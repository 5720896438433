import { useState, useEffect } from 'react';
import './fermi-assistant.scss';
import { marked } from 'marked';
import { getMinIndex } from '../../util/util';

const API_KEY_PW = 'xof8-tt72-1yy0-afie-7m3d-c09z';

export default function FermiAssistant() {
  const [apiKey, setApiKey] = useState('');
  const [problemDescription, setProblemDescription] = useState('');
  const [resultUnit, setResultUnit] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const [storeLocally, setStoreLocally] = useState(false);

  const allFieldsFilled = apiKey && problemDescription && resultUnit;

  useEffect(() => {
    const storedState = localStorage.getItem('fermiAssistantState');
    if (storedState) {
      const { apiKey, problemDescription, resultUnit, storeLocally } = JSON.parse(storedState);
      setApiKey(simpleDecryption(apiKey, API_KEY_PW));
      setProblemDescription(problemDescription);
      setResultUnit(resultUnit);
      setStoreLocally(storeLocally);
    }
  }, []);

  useEffect(() => {
    if (storeLocally) {
      const state = { apiKey: simpleEncryption(apiKey, API_KEY_PW), problemDescription, resultUnit, storeLocally };
      localStorage.setItem('fermiAssistantState', JSON.stringify(state));
    } else {
      localStorage.removeItem('fermiAssistantState');
    }
  }, [apiKey, problemDescription, resultUnit, storeLocally]);

  const handleRunEstimation = async () => {
    if (!allFieldsFilled) return;

    setLoading(true);
    setResults([]);

    const prompts = [
      'Please provide a Fermi estimation using a systematic breakdown.',
      'Approach the Fermi problem creatively and estimate with details.',
      'Come up with a bottom-up approach, generalizing from the individual to the overall quantity.',
      'Come up with a top-down approach, starting with a more global perspective.',
      'Come up with a very conservative Fermi estimate, going more for a lower bound of the quantity.',
    ];

    try {
      const fetchEstimations = prompts.map((prompt) => {
        const finalPrompt = getFullPrompt(prompt, problemDescription, resultUnit);
        return fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: 'gpt-4o',
            messages: [{ role: 'user', content: finalPrompt }],
          }),
        }).then((response) => response.json());
      });

      const responses = await Promise.all(fetchEstimations);
      const parsedResults = responses
        .map((response) => {
          const text = response.choices[0].message.content;
          const lastOpeningBracket = text.lastIndexOf('{');
          const lastClosingBracket = text.lastIndexOf('}');
          if (lastOpeningBracket === -1 || lastClosingBracket === -1) return null;
          const json = text.slice(lastOpeningBracket, lastClosingBracket + 1);
          const result = JSON.parse(json);
          let fullText = removeJsonFromText(text);
          result.textResponse = fullText;
          result.htmlResponse = parseMarkdown(fullText);
          return result;
        })
        .filter((result) => result !== null);

      setResults(parsedResults);
    } catch (error) {
      console.error('Error fetching estimations:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateOverallEstimate = (): number | null => {
    if (results.length === 0) return null;
    const totalWeight = results.reduce((acc, r) => acc + Math.sqrt(r.credence), 0);
    const weightedSum = results.reduce((acc, r) => acc + Math.sqrt(r.credence) * Math.log(r.estimate), 0);
    return Math.exp(weightedSum / totalWeight);
  };

  const formatNicely = (num: number | null): string => {
    if (num === null) {
      return 'No estimate available';
    }
    if (num === 0) {
      return '0';
    }
    // Round num to 3 significant digits
    num = Number(num.toPrecision(3));
    if (Math.abs(num) < 10000000 && Math.abs(num) > 0.0001) {
      // Just return the number, with ',' as thousands separator
      return num.toLocaleString('en-US');
    } else {
      // Return the number in scientific notation
      return num.toPrecision(2);
    }
  };

  const handleDownload = (format: 'txt' | 'html') => {
    const overallEstimate = formatNicely(calculateOverallEstimate());
    let content = `Problem Description:\n${problemDescription}\n\n`;
    content += `Overall Estimate: ${overallEstimate} ${resultUnit}\n\n`;
    content += `Results:\n`;

    results.forEach((result, index) => {
      content += `Estimate ${index + 1}: ${formatNicely(result.estimate)} ${resultUnit} (${result.credence}% confidence)\n`;
      content += `Approach: ${result.approach}\n`;
      content += `Uncertainty: ${result.uncertainty}\n`;
      content += `Full Response:\n${result.textResponse}\n\n`;
    });

    const blob = new Blob([format === 'html' ? marked(content) as string : content], {
      type: format === 'html' ? 'text/html' : 'text/plain',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `fermi_estimation.${format}`;
    link.click();
  };

  return (
    <div className="tool-fermi-assistant">
      <h1>Fermi Assistant</h1>
      <p>
        This tool provides some automated Fermi estimates, performed by ChatGPT (GPT-4o) based on your problem description.
        It will always try to get 5 independent estimates, and then provides you with the averaged value, the biggest uncertainties, and the individual estimates.
      </p>
      <p>
        Insert your data below and click "Run Estimation" to get started. Usually it takes less than 20 seconds to get the results.
      </p>
      <label>
        <input
          type="checkbox"
          checked={storeLocally}
          onChange={(e) => setStoreLocally(e.target.checked)}
        />
        Store values locally
      </label>
      <p className="info-text">
        If "store values locally" is checked, your API key (as well as the other inputs) will be stored in your browser's local storage.
        This data will not be sent to any rationaltools server or stored anywhere outside your device, and will only be used for direct OpenAI API requests.
        Usually, one such fermi estimation requests costs less than 0.05 USD, but depending on your problem description this may vary.<br />
        I obviously can't guarantee that the estimates are good or worth the money (or even that you will see a result at all - I didn't put too much effort into error handling yet),
        so please use this tool responsibly. Ideally use a separate API key
        with strong rate limits for this tool, to avoid unwelcome surprises on your OpenAI bill. 
      </p>
      <input
        type="password"
        placeholder="Enter your OpenAI API key"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
      />
      <textarea
        placeholder="Describe your problem for Fermi estimation"
        value={problemDescription}
        onChange={(e) => setProblemDescription(e.target.value)}
        maxLength={1500}
      />
      <input
        type="text"
        placeholder="Specify the unit for the result (e.g. 'kilometers', 'MWh', or 'billion dollars')"
        value={resultUnit}
        onChange={(e) => setResultUnit(e.target.value)}
        maxLength={200}
      />
      <button
        onClick={handleRunEstimation}
        disabled={!allFieldsFilled || loading}
      >
        {loading ? 'Running...' : 'Run Estimation'}
      </button>

      {loading && <div className="loading">Calculating Fermi estimations...</div>}

      {results.length > 0 && (
        <div className="results">
          <h2>Overall Estimate:</h2>
          <p><span className='result-num'>{formatNicely(calculateOverallEstimate())}</span> {resultUnit}</p>

          <h2>Results:</h2>
          {results.map((result, index) => (
            <div key={index} className="result-item">
              <strong>Estimate:</strong> <span className='result-num'>{formatNicely(result.estimate)}</span> {resultUnit} ({result.credence}% confidence)
              <br />
              <strong>Approach:</strong> {result.approach}
              <br />
              <strong>Uncertainty:</strong> {result.uncertainty}
              <br />
              <span
                className="collapsible"
                onClick={(e) => {
                  const details = e.currentTarget.nextSibling as HTMLElement;
                  if (details) details.style.display = details.style.display === 'none' ? 'block' : 'none';
                }}
              >
                Show/Hide Full Response
              </span>
              <div style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: result.htmlResponse }}></div>
            </div>
          ))}

          <h2>Uncertainties:</h2>
          <ul>
            {results.map((result, index) => (
              <li key={index}>{result.uncertainty}</li>
            ))}
          </ul>

          <button onClick={() => handleDownload('txt')}>Download as Text</button>
          <button onClick={() => handleDownload('html')}>Download as HTML</button>
        </div>
      )}
    </div>
  );
}

function getFullPrompt(promptCustomization: string, problemDescription: string, resultUnit: string) {
  const fullPrompt = `You're a superforecaster who performs Fermi estimations systematically. First, you outline how you will approach the estimate.
Then you will describe your steps briefly
and guess all the quantities to the best of your knowledge. It's fine if you don't at all know something, that's standard practice in
Fermi estimates - just take your best guess, but keep them very realistic. Generally be very concise and limit prose. Act rationally and skeptically. Try to actually come up
with realistic numbers, even if this means they may seem disappointing. Estimate as if you had to bet money on the result. You ultimate goal is
to end up as close to reality as possible. Before concluding your estimate, add another sanity check paragraph where you check the numbers you estimated for plausibility. If any are unrealistic on second thought, correct them.
End your message with a JSON object of this structure:<BR>
{ approach: <string>, estimate: <number>, credence: <number between 0 and 100>, uncertainty: <string> }<BR>
Here, approach is a very brief description on how you performed the estimate (ideally 5-20 words), and give a rough idea of which angle you took to do the estimate.
The estimate is your final estimate result, make very sure this is in the following unit: ${resultUnit} and also ensure it is a JSON compatible number and not a string.
Credence is a number between 0 and 100 that reflects how sure you are of your estimate. It's a very rough quantification that should just give a
broad idea of how speculative your estimate is. Higher numbers mean your estimate seems quite robust. Just pick whatever feels right here, no need to overthink this.<BR>
Uncertainty is a string where you briefly (again roughly 5-20 words) describe what the biggest uncertainty in your estimation is. This could be some
particular quantity or sub-problem, or something about the problem definition, uncertainty about some involved intervention or whatever else.<BR>
Make sure to wrap your resulting json in curly brackets so that it can get parsed properly.
${promptCustomization}
Please perform your Fermi estimate on the following subject: ${problemDescription}`;
  const cleaned = fullPrompt.replaceAll('\n', ' ').replace(/<BR>/g, '\n');
  return cleaned;
}

/**
 * Using some very basic encryption to make sure the stored API key is not stored in plain text.
 */
function simpleEncryption(text: string, password: string) {
  let result = '';
  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i) ^ password.charCodeAt(i % password.length);
    result += String.fromCharCode(charCode);
  }
  return result;
}

function simpleDecryption(text: string, password: string) {
  let result = '';
  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i) ^ password.charCodeAt(i % password.length);
    result += String.fromCharCode(charCode);
  }
  return result;
}

function parseMarkdown(text: string) {
  let result = marked(text) as string;
  // Replace some LaTeX symbols with more readable versions
  result = result.replaceAll('\\times', '×');
  result = result.replaceAll('\\approx', '≈');
  result = result.replaceAll('\\neq', '≠');
  result = result.replaceAll('\\leq', '≤');
  result = result.replaceAll('\\geq', '≥');
  result = result.replaceAll('\\infty', '∞');
  result = result.replace(/\\text\{([^}]*)\}/g, '$1');
  return result;
}

function removeJsonFromText(text: string) {
  const lastOpeningBracket = text.lastIndexOf('{');
  const stringsToCheck = ['```json', '# json', '## json', '### json', '#### json'];
  const lowerText = text.toLowerCase();
  const index = getMinIndex(lastOpeningBracket, ...stringsToCheck.map((s) => lowerText.indexOf(s)));
  if (index < 0) {
    return text;
  }
  return text.substring(0, index);
}
