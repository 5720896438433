
const SAMPLE_COUNT = 500;



function getRandomIndex(array: any[]): number {
    const i = Math.floor(Math.random() * array.length);
    return i;
}

function getRandomItem<T>(array: T[]): T {
    return array[getRandomIndex(array)];
}

function takeRandomItem<T>(array: T[]): T {
    const i = getRandomIndex(array);
    return array.splice(i, 1)[0];
}

type Person = string;

export enum PersonState {
    INVOLVED,
    OPTIONAL,
    EXCLUDED,
}

export type SessionSettings = {
    groupCount: number,
    personStates: Map<Person, PersonState>,
}

export class Session {
    public groups: Person[][] = [];

    public constructor(
        public name: string,
        public readonly persons: Person[],
    ) {

    }

    public setGroups(groups: Person[][]) {
        this.groups = groups;
    }

    public static createPredefined(name: string, groups: Person[][]) {
        const personSet = new Set<Person>();
        for (const g of groups) {
            for (const p of g) {
                personSet.add(p);
            }
        }
        const persons = Array.from(personSet);
        const session = new Session(name, persons);
        session.setGroups(groups);
        return session;
    }

    public static createRandom(name: string, persons: Person[], settings: SessionSettings, previousSessions: Session[]) {
        const session = new Session(name, persons);
        session.shuffle(settings, previousSessions);
        return session;
    }

    public shuffle(
        settings: SessionSettings,
        previousSessions: Session[],
    ): Person[][] {
        // Count partners in past
        const persons = this.persons.filter(p => settings.personStates.get(p) !== PersonState.EXCLUDED);
        console.log('New session with ', persons.length, ' persons', persons, settings, previousSessions);
        const partnerCount: number[][] = [];
        // Initialize partner count from past as 0 for all pairs
        for (let p = 0; p < persons.length; p++) {
            partnerCount[p] = [];
            for (let q = 0; q < persons.length; q++) {
                partnerCount[p][q] = 0;
            }
        }
        // Check history and count
        for (const s of previousSessions) {
            for (const g of s.groups) {
                const penalty = 1 + 1 / g.length;
                for (let p = 1; p < g.length; p++) {
                    const pi = persons.indexOf(g[p]);
                    if (pi < 0) { break; }
                    for (let q = 0; q < p; q++) {
                        const qi = persons.indexOf(g[q]);
                        if (qi < 0) { break; }
                        partnerCount[pi][qi] += penalty;
                        partnerCount[qi][pi] += penalty;
                    }
                }

            }
        }
        console.log('Partner count initialized', partnerCount.map(p => p.join('')).join(','), 'for', persons, 'based on', previousSessions);
        // Generate grouping samples and pick best one we encounter
        let bestGrouping: Person[][] = [];
        let bestRating = Infinity;
        for (let i = 0; i < SAMPLE_COUNT; i++) {
            const grouping = this._shuffle(settings, persons);
            const rating = this.computeRating(persons, grouping, partnerCount);
            if (rating < bestRating) {
                console.log('New best found at ', i, ': ', rating);
                bestGrouping = grouping;
                bestRating = rating;
                if (bestRating <= 0) {
                    // No need to continue if grouping is perfect
                    break;
                }
            }
        }
        this.groups = bestGrouping;
        return bestGrouping;
    }


    private _shuffle(
        settings: SessionSettings,
        persons: Person[],
    ) {
        persons = [...persons];
        // Set up empty groups
        const groups: Person[][] = [];
        for (let i = 0; i < settings.groupCount; i++) {
            groups[i] = [];
        }
        if (settings.groupCount >= persons.length) {
            // Not much grouping to do, return trivial result
            let g = 0;
            while (persons.length) {
                const person = takeRandomItem(persons);
                groups[g].push(person);
                g++;
            }
            return groups;
        }
        // Otherwise we can group people properly
        while (persons.length) {
            const person = takeRandomItem(persons);
            const minimumGroupSize = groups.reduce((min, group) => Math.min(min, group.length), Infinity);
            const minimalGroups = groups.filter(g => g.length === minimumGroupSize);
            getRandomItem(minimalGroups).push(person);
        }
        return groups;
    }

    private computeRating(
        persons: Person[],
        grouping: Person[][],
        partnerCount: number[][],
    ) {
        let penalty = 0;
        for (const g of grouping) {
            for (let p = 1; p < g.length; p++) {
                const pi = persons.indexOf(g[p]);
                for (let q = 0; q < p; q++) {
                    const qi = persons.indexOf(g[q]);
                    penalty += partnerCount[pi][qi];
                }
            }
        }
        return penalty;
    }

    public serialize() {
        return {
            name: this.name,
            groups: this.groups.map(g => g.map(p => p)),
        };
    }

    public static deserialize(data: any, persons: Person[]) {
        const session = new Session(data.name, persons);
        session.groups = data.groups.map((g: string[]) => g.map(name => persons.find(p => p === name)));
        return session;
    }
}


export class PairUp {
    public persons: Person[];
    public sessions: Session[];

    public constructor(names: string[]) {
        this.persons = names.map(name => name);
        this.sessions = [];
    }

}




// Test the implementation
// const names = ["Alice", "Bob", "Charlene", "Darwin", "Ember", "Frodo", "Gina", "Harry", "Irina", "James", "Kathy", "Larry"];

// const pairup = new PairUp(names);

// for (let i = 0; i < 5; i++) {
//     const session = Session.createRandom(
//         "Session " + (i + 1),
//         pairup.persons,
//         {
//             groupCount: Math.floor(2 + Math.random() * 5),
//             personStates: new Map(),
//         },
//         pairup.sessions,
//     );
//     console.log(session.name, session.groups.map(g => g.map(p => p.name).join(', ')));
//     pairup.sessions.push(session);
// }