
/**
 * From a number of index values, return the index of the minimum value that is not -1.
 * If no index is >= 0, -1 will be returned.
 * @param indices 
 * @returns The index of the minimum value that is not -1, or -1 if no such value exists.
 */
export function getMinIndex(...indices: number[]): number {
    let minIndex = -1;
    let minValue = Infinity;
    for (let i = 0; i < indices.length; i++) {
        if (indices[i] >= 0 && indices[i] < minValue) {
            minValue = indices[i];
            minIndex = i;
        }
    }
    return minIndex < 0 ? -1 : minValue;
}