import React, { useState, useRef, useEffect } from 'react';
import { PairUp, Session, SessionSettings, PersonState } from './pair-up-logic';
import './pair-up.scss';

let personCounter = 0;

type PeopleType = {
  key: string;
  name: string;
}

export default function PairUpComponent() {

  const [people, setPeople] = useState<PeopleType[]>([]);
  const [available, setAvailable] = useState<boolean[]>([]);
  const [sessions, setSessions] = useState<Session[]>([]);
  const pairUpRef = useRef<PairUp>(new PairUp([]));

  // Load state from localStorage on startup
  useEffect(() => {
    const savedState = localStorage.getItem('pairUpState');
    if (savedState) {
      try {
        const parsed = JSON.parse(savedState);
        console.log(parsed);
        if (parsed.people && parsed.available && parsed.sessions) {
          setPeople(parsed.people);
          personCounter = parsed.people.length;
          setAvailable(parsed.available);
          const parsedSessions = parsed.sessions.map((s: any) => Session.deserialize(s, parsed.people.map((p: PeopleType) => p.name)));
          setSessions(parsedSessions);
          console.log('Loaded state:', people, available, sessions);
          // Reconstruct PairUp instance
          pairUpRef.current = new PairUp(parsed.people.map((p: {name: string}) => p.name));
          pairUpRef.current.sessions = parsedSessions;
          // Update personCounter
          personCounter = parsed.people.length;
        }
      } catch (e) {
        console.error('Failed to parse saved state:', e);
      }
    }
  }, []);

  // Save state to localStorage on every change
  useEffect(() => {
    console.log('Saving state')
    if (people.length === 0) {
      return;
    }
    const state = {
      people,
      available,
      sessions: sessions.map(s => s.serialize()),
    };
    localStorage.setItem('pairUpState', JSON.stringify(state));
  }, [people, available, sessions]);

  const handleNameChange = (index: number, newName: string) => {
    setPeople((prevPeople) => {
      const newPeople = [...prevPeople];
      newPeople[index].name = newName;
      // Update PairUp instance
      pairUpRef.current.persons[index] = newName;
      return newPeople;
    });
  };

  const handleAddPerson = (atIndex = people.length) => {
    const key = 'person' + ++personCounter;
    console.log('Created key: ', key);
    const newPerson: PeopleType = { key, name: '' };
    setPeople((prevPeople) => {
      const updatedPeople = [
        ...prevPeople.slice(0, atIndex),
        newPerson,
        ...prevPeople.slice(atIndex)
      ];
      // Add to PairUp instance
      if (pairUpRef.current.persons.length < updatedPeople.length) {
        pairUpRef.current.persons.splice(atIndex, 0, '');
      }
      return updatedPeople;
    });
    setAvailable((prevAvailable) => {
      const updatedAvailable = [
        ...prevAvailable.slice(0, atIndex),
        true,
        ...prevAvailable.slice(atIndex)
      ];
      return updatedAvailable;
    });
    // Focus on the new input
    setTimeout(() => {
      const newInput = document.querySelector('.people-input:nth-of-type(' + (atIndex + 1) + ') .people-name') as HTMLInputElement;
      newInput?.focus();
    }, 0);
  };

  const handleClearAll = () => {
    const sure = window.confirm('Are you sure you want to clear all participants?');
    if (!sure) {
      return;
    }
    setPeople([]);
    setAvailable([]);
    setSessions([]);
    // Clear PairUp instance
    pairUpRef.current.persons = [];
  }

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === 'Enter') {
      handleAddPerson();
    } else if (e.key === 'Backspace' && people[index].name === '') {
      setPeople((prevPeople) => {
        const newPeople = [...prevPeople];
        newPeople.splice(index, 1);
        // Remove from PairUp instance
        pairUpRef.current.persons.splice(index, 1);
        return newPeople;
      });
      setAvailable((prevAvailable) => {
        const newAvailable = [...prevAvailable];
        newAvailable.splice(index, 1);
        return newAvailable;
      });
      // Focus on the previous input (index - 1)
      setTimeout(() => {
        const newIndex = Math.max(0, index - 1);
        const prevInput = document.querySelector('.people-input:nth-of-type(' + (newIndex + 1) + ') .people-name') as HTMLInputElement;
        prevInput?.focus();
      }, 0);
    }
  };

  const generateGroups = () => {
    const countInput = prompt('How many groups?');
    const groupCount = countInput ? parseInt(countInput) : 0;
    if (groupCount <= 0) {
      alert('Invalid group count');
      return;
    }
    const settings: SessionSettings = {
      groupCount,
      personStates: new Map<string, PersonState>(),
    };
    console.log('Persons:', pairUpRef.current.persons.slice(), ' for generation');
    console.log('Basing on sessions', pairUpRef.current.sessions.slice());
    pairUpRef.current.persons.forEach((person, index) => {
      settings.personStates.set(person, available[index] ? PersonState.INVOLVED : PersonState.EXCLUDED);
    });
    const session = Session.createRandom(
      "Session " + (sessions.length + 1),
      pairUpRef.current.persons,
      settings,
      pairUpRef.current.sessions
    );
    setSessions([...sessions, session]);
    pairUpRef.current.sessions.push(session);
  };

  const handleGroupDeletion = (index: number) => {
    const sure = window.confirm('Are you sure you want to delete this session?');
    if (!sure) {
      return;
    }
    setSessions((prevSessions) => {
      const newSessions = [...prevSessions];
      newSessions.splice(index, 1);
      // Remove from PairUp instance
      pairUpRef.current.sessions.splice(index, 1);
      return newSessions;
    });
  }

  return (
    <div className="tool-pairup">
      <h2>Pair-Up</h2>

      {/* ** List of Participants ** */}
      <h3>Participants</h3>
      {/* Existing ones */}
      { people.map((p, index) => 
        <div className="people-input" key={p.key}>
          <input
            className="people-name"
            value={p.name}
            onChange={e => {
              handleNameChange(index, e.target.value);
            }}
            onKeyDown={e => handleKeyDown(e, index)}
            placeholder="name"
          />
          <button
            className={`people-available ${available[index] ? 'active' : 'inactive'}`}
            onClick={e => {
              setAvailable((prevAvailable) => {
                const newAvailable = [...prevAvailable];
                newAvailable[index] = !newAvailable[index];
                console.log('Available', newAvailable.slice());
                console.log('Persons', pairUpRef.current.persons.slice());
                return newAvailable;
              });
            }}
          >{available[index] ? 'Involved' : 'Out'}</button>
        </div>)
      }
      {/* Add person */}
      <button id="tool-pairup-add-person" onClick={e => handleAddPerson()}>Add Person</button>
      <button id="tool-pairup-clear-all" onClick={e => handleClearAll()}>Clear All</button>

      {/* Display Sessions */}
      <div className="sessions">
        {sessions.map((session, idx) => (
          <div key={idx}>
            <h4>
              {session.name}
              <button onClick={e => handleGroupDeletion(idx)}>x</button>
            </h4>
            {session.groups.map((group, gIdx) => (
              <p key={gIdx}><span className="group-id">Group {gIdx + 1}:</span> {group.map(p => p).join(', ')}</p>
            ))}
          </div>
        ))}
      </div>
      
      {/* Generate Groups */}
      <button id="new-session-button" onClick={generateGroups}>New Session</button>
    </div>
  );
}