import React, { ChangeEvent, useEffect, useState } from 'react';
import './styles.scss';

type MeditationType = {
  title: string,
  youtube: string,
  download?: string,
  hasMusic: boolean,
  thumbnail?: string,
  duration: number,
}

const podcastURL = 'https://podtube.me/rssfeed/UCUWDjjqowNnQSGzX2G4OxpA';

const meditations: MeditationType[] = [
  med('Bliss', 'https://www.youtube.com/watch?v=z0e_OtoC6rE', false, dur(10, 50), 'bliss.mp3'),
  med('Nondual Awareness', 'https://www.youtube.com/watch?v=mR5fa0NmDN4', false, dur(12, 52), 'nondual.mp3'),
  med('Productive Morning', 'https://www.youtube.com/watch?v=zOtWIXtMrsQ', false, dur(9, 16), 'morning.mp3'),
  med('Guacamole Meditation', 'https://www.youtube.com/watch?v=ZjC6-U4W7jU', false, dur(10, 20), 'guacamole.mp3'),
  med('Overcoming Imposter Syndrome', 'https://www.youtube.com/watch?v=KzRberrEcT0', false, dur(19, 19), 'imposter.mp3'),
  med('Getting to Terms with Aging', 'https://www.youtube.com/watch?v=FQEFDlvXVUg', false, dur(27, 45), 'aging.mp3'),
  med('Visualizations', 'https://www.youtube.com/watch?v=G96mBbyP6_4', true, dur(12, 22), 'visualizations.mp3'),
  med('Feelings of Shame', 'https://www.youtube.com/watch?v=-pN8PgZ4dQU', true, dur(14, 12), 'shame.mp3'),
  med('10 Minute Relaxation', 'https://www.youtube.com/watch?v=k-HdRjo-pok', true, dur(10, 13), 'relaxation.mp3'),
  med('Feel the AGI', 'https://www.youtube.com/watch?v=KzN9rVI_Uyo', true, dur(16, 3), 'agi.mp3', 'https://i.ytimg.com/vi/KzN9rVI_Uyo/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBgBFE9jF-m0Pc-eqwbKPCos9l-kA'),
];

export default function GuidedMeditations() {
  return (
    <div className="tool-guided-meditations">
      <h1>Guided Meditations</h1>
      <p>
        Below you find a set of <strong>AI generated guided meditations</strong> of different kinds. You can listen to them directly on Youtube, or download them as mp3 files.
        Alternatively you can also listen to them as a <strong>podcast</strong>, using this URL:
      </p>
      <p style={{display: 'inlineBlock', textAlign: 'center'}}><a href={podcastURL} target='_blank'>{podcastURL}</a></p>
      <p>Tools used to generate these meditations: ChatGPT, Claude, Elevenlabs, Suno (<MusicIcon />)</p>
      <div className="meditations-grid">
        {meditations.map(med => MeditationTile(med))}
      </div>
    </div>
  );
}

function MeditationTile(med: MeditationType) {
  const formatTime = (s: number) => {
    const min = Math.floor(s / 60);
    const sec = s % 60;
    return `${min}:${sec.toString().padStart(2, '0')}`;
  }

  return (
    <div className="meditation-tile">
      <div className="meditation-thumbnail">
        <img src={med.thumbnail} alt={med.title} />
      </div>
      <h2>{med.title} {med.hasMusic && <MusicIcon />}</h2>
      <div className="meditation-duration">{formatTime(med.duration)}</div>
      <div className="meditation-links">
        <a className="youtube-button" href={med.youtube} target="_blank" rel="noreferrer">Youtube</a>
        {med.download && <a className="download-button" href={med.download} download>Download</a>}
      </div>
    </div>
  );
}

function MusicIcon() {
  return (
    <div className="music-icon" title="Meditation contains music">
      <img src="/img/music-solid.svg" alt="Music icon" />
    </div>
  );
}

function med(title: string, youtube: string, hasMusic = false, duration: number, download?: string, thumbnail?: string): MeditationType {
  const med: MeditationType = { title, youtube, hasMusic, duration };
  if (download) {
    // If link is relative, redirect it to public/download folder
    if (!download.startsWith('http')) {
      download = `/download/${download}`;
    }
    med.download = download;
  }
  if (thumbnail) {
    med.thumbnail = thumbnail;
  } else {
    // Turn youtube links into thumbnail links
    const videoId = youtube.split('v=')[1];
    if (videoId) {
      med.thumbnail = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
    }
  }
  return med;
}

function dur(min: number, s: number): number {
  return min * 60 + s;
}