import { IconName } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import './tools.scss'

type ToolType = {
    title: string;
    link: string;
    description?: string;
    img?: string;
    icon?: IconName;
}

const tools: ToolType[] = [
    {
        title: 'Reflection Workshop',
        link: '/reflection-workshop',
        description: 'A customizable set of minimalistic, immersive slides, with a looping Youtube background. Ideal for end-of-year reflection sessions with a group.',
        img: '/img/couch-solid.svg', // From https://fontawesome.com/icons/couch?f=classic&s=solid
    },
    {
        title: 'Pair-Up',
        link: '/pairup',
        description: 'A matchmaking type tool, to easily build pairs/groups of people in live workshops, without having to have people go through the awkward partner finding dance.',
        img: '/img/people-arrows-solid.svg',
    },
    {
        title: 'Reward Randomizer',
        link: '/reward-randomizer',
        description: 'A simple tool to provide randomize rewards. Ideal for personal gamification.',
        img: '/img/star-solid.svg',
    },
    {
        title: 'Fermi Assistant',
        link: '/fermi-assistant',
        description: 'Using LLMs to help you solve Fermi problems. Requires OpenAI API key.',
        img: '/img/chart-simple.svg',
    },
    {
        title: 'Guided AI Meditations',
        link: '/guided-meditations',
        description: 'A set of fully AI generated guided meditations of different kinds. Available as youtube link, podcast, and mp3 download.',
        img: '/img/mug-saucer-solid.svg',
    },
    {
        title: 'JS Image Editor',
        link: '/tools/image-editor/editor.html',
        description: 'A javascript based image editor that allows you to manipulate pixels with code. Comes with a ChatGPT setup prompt to get AI support for using the tool.',
        img: '/img/paintbrush-solid.svg',
    },
];

export default function ToolsPage() {
  return (
    <div className="page-tools">
        <h2>Tools</h2>
        <div className="tools-grid">
            {tools.map(tool => ToolTile(tool))}
        </div>
    </div>
  );
}

function ToolTile(tool: ToolType) {
    return (
        <div className="tool-wrapper" key={tool.link}>
            {tool.link.includes('.')
                ? <a href={tool.link}> {ToolTileContent(tool)} </a>
                : <Link to={tool.link}> {ToolTileContent(tool)} </Link>
            }
        </div>
    )
}

function ToolTileContent(tool: ToolType) {
    return <div className="tool-tile">
        <h3>{tool.title}</h3>
        {tool.img ? <img src={tool.img} className="tool-tile-img" /> : null}
        {tool.icon && !tool.img ? <FontAwesomeIcon icon={tool.icon} /> : null}
        {tool.description ? <p className="tool-tile-description">{tool.description}</p> : null}
    </div>
}